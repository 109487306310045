<template>
  <div class="rtl">
    <v-card>
      <v-card-title>
        موجودی حسابداری
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="assets" :search="search">
        <template v-slot:item.lastBlockchainUpdateAtUtc="{ item }">
          {{item.lastBlockchainUpdateAtUtc != null ? getPersianDate(item.lastBlockchainUpdateAtUtc) : ''}}
        </template>
        <template v-slot:item.totalBalanceInInternalAccountingSystem="{ item }">
          {{toPlain(item.totalBalanceInInternalAccountingSystem)}}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axiosApi from "@/axios";
import getPersianDateTime from "@/plugins/getPersianDate";
import {toPlainString} from "@/components/toPlainString";
export default {
name: "ExchangeInventory",
  data(){
    return {
      assets : [],
      search : '',
      headers : [
          {text: 'نام کوین', value: 'asset.persianTitle'},
          {text: 'نماد', value: 'asset.symbol'},
          {text: 'حسابداری داخلی', value: 'totalBalanceInInternalAccountingSystem'},
          {text: 'بلاکچین', value: 'totalBalanceInBlockchain'},
          {text: 'واریزی به بلاک چین', value: 'totalBlockchainIncoming'},
          {text: 'برداشت از بلاک چین', value: 'totalBlockchainOutgoing'},
          {text: 'تاریخ آخرین آپدیت', value: 'lastBlockchainUpdateAtUtc'},
      ],
    }
  },
  mounted() {
  this.getAsset()
  },
  methods : {
    getAsset()
    {
      axiosApi().post('/api/Manage/v1/Report/GetTotalBalanceInInternalAccountingSystemAndBlockchain')
        .then(({data}) => {
          if (data['isSuccess'] === true)
            this.assets = data.data
        })
    },
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
    toPlain(item)
    {
      return toPlainString(item)
    }
  }
}
</script>

<style scoped>

</style>
